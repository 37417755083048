<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">
      <div>
        <span>{{ $tc('label.apuracao', 1)}}</span>
        <popover-lista :objeto="acaoFormatada" :campos="listaCamposPopover()"/>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <card-info :campos="listaCampos()" :objeto="acaoFormatada">
        <template slot="antes">
          <v-col cols="12" sm="6" md="3" class="pr-2" v-if="acao.metas">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="400"
              bottom
              offset-y>
              <template v-slot:activator="{ on }">
                <v-select
                  v-model="item"
                  :items="items"
                  append-icon
                  class="select--sem--linha"
                  :label="$tc('label.meta', 1)"
                  multiple
                  readonly>
                  <template v-slot:selection="data">
                    <v-icon v-on="on">{{ data.item }}</v-icon>
                  </template>
                </v-select>
              </template>

              <v-card>
                <v-data-table
                  :headers="headers()"
                  :items="acao.metas"
                  hide-default-footer
                  :no-data-text="$t('label.tabela_sem_conteudo')">
                  <template v-slot:item.meta_de="{ item }">
                    <span v-text="exibirMeta(item.vlrMetaDe)"></span>
                  </template>
                  <template v-slot:item.meta_ate="{ item }">
                    <span v-if="item.vlrMetaAte" v-text="exibirMeta(item.vlrMetaAte)"></span>
                  </template>
                  <template v-slot:item.bonificacao_pagamento="{ item }">
                    <span v-text="exibirPagamento(item.vlrBonificacao)"></span>
                  </template>
                </v-data-table>
              </v-card>
            </v-menu>
          </v-col>
        </template>
      </card-info>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CardInfo from '../../../shared-components/CardInfo';
import PopoverLista from '../../../shared-components/PopoverLista';
import { convertToMoney, convertToPercent, convertToNumberFormat } from '../../../common/functions/helpers';

export default {
  props: {
    acao: Object,
    configuracao: Object,
  },
  components: {
    CardInfo,
    PopoverLista,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),

      campos: [
        { campo: 'valorRealizado', label: this.$tc('label.realizado') },
        { campo: 'valorAcao', label: this.$tc('label.valor_acao', 1) },
        { campo: 'valorRecebimento', label: this.$tc('label.valor_recebimento') },
      ],
      camposPopover: [
        { campo: 'periodoApuracao', label: this.$tc('label.periodo_apuracao', 1) },
        { campo: 'calculoApuracao', label: this.$tc('label.calculo_apuracao', 1) },
        { campo: 'nomeUnidadeMedida', label: this.$tc('label.unidade_medida', 1) },
        { campo: 'valorBonificacao', label: this.$tc('label.forma_bonificacao', 1) },
        { campo: 'calculoBonificacao', label: this.$tc('label.calculo_bonificacao', 1) },
        { campo: 'baseCalculo', label: this.$tc('label.base_calculo', 1) },
        { campo: 'descontos', label: this.$tc('label.desconto', 1) },
      ],
      menu: false,
      item: 'attach_money',
      items: ['attach_money'],
      acaoFormatada: {},
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getProdutoMetadado',
    ]),
    calculoVolume() {
      if (this.acao.calculoApuracao
        && (this.acao.calculoApuracao === 'SELLIN_VOLUME'
          || this.acao.calculoApuracao === 'SELLOUT_VOLUME')) {
        return true;
      }
      return false;
    },
    calculoValor() {
      if (this.acao.calculoApuracao
          && (this.acao.calculoApuracao === 'SELLIN_VALOR'
            || this.acao.calculoApuracao === 'SELLOUT_VALOR')) {
        return true;
      }
      return false;
    },
    calculoPercentual() {
      if (this.acao.calculoApuracao
          && this.acao.calculoApuracao === 'SELLIN_PERCENTUAL') {
        return true;
      }
      return false;
    },
    metaPorQuantidade() {
      return this.configuracao
        && this.configuracao.templateCalculo === 'META_POR_QUANTIDADE';
    },
    unidadeQuantidade() {
      return this.metaPorQuantidade;
    },
    verbaPercentual() {
      if (this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'PERCENTUAL') {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setProdutoMetadado',
    ]),
    listaCampos() {
      const campos = [].concat(this.campos);
      if (this.configuracao.passo3) {
        const { listaProduto } = this.configuracao.passo3;
        const camposApuracao = listaProduto.filter((p) => p.fluxos.includes('LIQUIDACAO') && !p.excecao);
        camposApuracao.forEach((a) => campos.push({
          campo: a.label,
          extensao: !a.indProduto,
          label: a.indProduto ? a.label[0].toUpperCase() + a.label.slice(1)
            : this.montaLabelExtensao(a.label),
        }));
      }
      return campos;
    },
    listaCamposPopover() {
      const campos = [].concat(this.camposPopover);
      if (this.configuracao.passo3) {
        const { listaProduto } = this.configuracao.passo3;
        const camposApuracao = listaProduto.filter((p) => p.fluxos.includes('LIQUIDACAO') && p.excecao);
        camposApuracao.forEach((a) => campos.push({ campo: a.label, excecao: true }));
      }
      return campos;
    },
    formatarObjeto() {
      this.acaoFormatada = { ...this.acao };
      if (this.acao && this.acao.valorAcao) {
        this.acaoFormatada.valorAcao = convertToMoney(this.acao.valorAcao);
      }
      if (this.acao && this.acao.valorRecebimento) {
        this.acaoFormatada.valorRecebimento = convertToMoney(this.acao.valorRecebimento);
        this.acaoFormatada
          .valorRecebimentoOriginal = convertToMoney(this.acao.valorRecebimentoOriginal);
      }
      if (this.acaoFormatada.valorBonificacao) {
        if (this.acao.formaBonificacao && this.acao.formaBonificacao === 'PERCENTUAL') {
          this.acaoFormatada.valorBonificacao = convertToPercent(this.acao.valorBonificacao);
        } else {
          this.acaoFormatada.valorBonificacao = convertToMoney(this.acao.valorBonificacao);
        }
      }
      if (this.acao.valorRealizado) {
        if (this.acao.calculoApuracao && this.acao.calculoApuracao.includes('VALOR')) {
          this.acaoFormatada.valorRealizado = convertToMoney(this.acao.valorRealizado);
          this.acaoFormatada
            .valorRealizadoOriginal = convertToMoney(this.acao.valorRealizadoOriginal);
        } else {
          this.acaoFormatada.valorRealizado = `${convertToNumberFormat(this.acao.valorRealizado)} ${this.acao.siglaUnidadeMedida || ''}`;
          this.acaoFormatada
            .valorRealizadoOriginal = `${convertToNumberFormat(this.acao.valorRealizadoOriginal)} ${this.acao.siglaUnidadeMedida || ''}`;
        }
      }

      if (this.acao && this.acao.periodoApuracao) {
        this.acaoFormatada.periodoApuracao = '';
        const listaPeriodo = this.acao.periodoApuracao.split(';');
        listaPeriodo.forEach((p, i) => {
          this.acaoFormatada.periodoApuracao = this.acaoFormatada.periodoApuracao.concat(`${this.$tc(`label.${p.toLowerCase()}`, 1)}`);
          if (i < (listaPeriodo.length - 1)) {
            this.acaoFormatada.periodoApuracao = this.acaoFormatada.periodoApuracao.concat(';');
          }
        });
      }

      if (this.acao && this.acao.calculoBonificacao) {
        this.acaoFormatada.calculoBonificacao = this.$tc(`label.${this.acao.calculoBonificacao.toLowerCase()}`, 1);
      }
      if (this.acao && this.acao.baseCalculo) {
        this.acaoFormatada.baseCalculo = this.$tc(`label.${this.acao.baseCalculo.toLowerCase()}`, 1);
      }
      if (this.acao && this.acao.calculoApuracao) {
        this.acaoFormatada.calculoApuracao = this.$tc(`label.${this.acao.calculoApuracao.toLowerCase()}`, 1);
      }
      if (this.acao && this.acao.tipoPagamento) {
        this.acaoFormatada.tipoPagamento = this.$tc(`configuracao.TIPO_PAGAMENTO.${this.acao.tipoPagamento.toUpperCase()}`, 1);
      }
      if (this.acao && this.acao.formaBonificacao) {
        this.acaoFormatada.formaBonificacao = this.$tc(`label.${this.acao.formaBonificacao.toLowerCase()}`, 1);
      }
      if (this.acao && this.acao.descontos) {
        this.acaoFormatada.descontos = '';
        const listaDescontos = this.acao.descontos.split(';');
        listaDescontos.forEach((p, i) => {
          this.acaoFormatada.descontos = this.acaoFormatada.descontos.concat(`${this.$tc(`label.${p.toLowerCase()}`, 1)}`);
          if (i < (listaDescontos.length - 1)) {
            this.acaoFormatada.descontos = this.acaoFormatada.descontos.concat(';');
          }
        });
      }

      this.listaCampos().filter((c) => c.extensao).forEach((c) => {
        if (this.acao && this.acao[c.campo]) {
          this.acaoFormatada[c.campo] = this.acao[c.campo].nomExtensao;
          if (!this.acaoFormatada[c.campo]) {
            this.acaoFormatada[c.campo] = '';
            this.acao[c.campo].forEach((o, i) => {
              this.acaoFormatada[c.campo] = this.acaoFormatada[c.campo].concat(o.nomExtensao);
              if (i < (this.acao[c.campo].length - 1)) {
                this.acaoFormatada[c.campo] = this.acaoFormatada[c.campo].concat(';');
              }
            });
          }
        }
      });

      this.listaCamposPopover().filter((c) => c.excecao).forEach((c) => {
        if (this.acao && this.acao[c.campo]) {
          this.acaoFormatada[c.campo] = this.acao[c.campo].nomExtensao;
          if (!this.acaoFormatada[c.campo]) {
            this.acaoFormatada[c.campo] = '';
            this.acao[c.campo].forEach((o, i) => {
              this.acaoFormatada[c.campo] = this.acaoFormatada[c.campo].concat(o.nomExtensao);
              if (i < (this.acao[c.campo].length - 1)) {
                this.acaoFormatada[c.campo] = this.acaoFormatada[c.campo].concat(';');
              }
            });
          }
        }
      });
    },
    montaLabelExtensao(campo) {
      const metadado = Object.values(this.getProdutoMetadado.mapaEntidades)
        .filter((dep) => dep.label === campo)[0];

      return metadado.entidadeEstrangeira;
    },
    headers() {
      return [
        {
          text: this.$t('label.meta_de'), value: 'meta_de', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.meta_ate'), value: 'meta_ate', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.verba'), value: 'bonificacao_pagamento', sortable: false, align: 'center',
        },
      ];
    },
    exibirMeta(valor) {
      if (this.calculoVolume && !this.unidadeQuantidade) {
        return convertToNumberFormat(valor);
      }
      if (this.calculoPercentual && !this.unidadeQuantidade) {
        return convertToPercent(valor);
      }
      if (this.calculoValor && !this.unidadeQuantidade) {
        return convertToMoney(valor);
      }
      return convertToNumberFormat(valor, {
        precision: 2,
      });
    },
    exibirPagamento(valor) {
      if (this.verbaPercentual) {
        return convertToPercent(valor);
      }
      return convertToMoney(valor);
    },
  },
  beforeMount() {
    this.setProdutoMetadado({
      resource: this.metadadoResource.listarProduto,
    });
  },
};
</script>
<style>
  .select--sem--linha > .v-input__control > .v-input__slot:before {
    width: 0% !important;
  }
  .select--sem--linha > .v-input__control > .v-input__slot:after {
    border-style: none;
  }
</style>
