<template>
  <div>
    <v-row class="pa-0 mx-0">
      <slot name="antes" />
      <template v-for="item in campos">
      <v-col cols="12" sm="6" md="3" class="pl-0" v-if="objeto[item.campo]" :key="item.campo">
        <v-textarea :label="inputLabel(item)" v-if="item.i18n" :value="$tc(objeto[item.campo], 1)" :disabled="true" :hint="hint(item)" persistent-hint auto-grow rows="1"></v-textarea>
        <v-textarea :label="inputLabel(item)" v-else v-model="objeto[item.campo]" :disabled="true" :hint="hint(item)" persistent-hint auto-grow rows="1"></v-textarea>
      </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({

  }),
  props: {
    campos: {
      type: Array,
      default: () => [],
    },
    objeto: Object,
  },
  methods: {
    inputLabel(item) {
      if (item.label) {
        return item.label;
      }
      return this.$tc(`label.${item.campo}`, 1);
    },
    hint(item) {
      if (this.objeto[item.hint]) {
        return String(this.objeto[item.hint]);
      }
      return '';
    },
  },
};
</script>
