<template>
  <div class="px-6">
    <v-card-title primary-title class="card-header mx-0 px-0 py-2">
      {{ $t('label.acao_avulsa') }} :: {{ acao.idAcao }} - {{ acao.idApuracao }} :: {{ acao.tipoAcao }} :: {{ acao.divisao }}
      <popover-lista :objeto="formatarObjeto()" :campos="listaPopover()"/>
    </v-card-title>

    <div class="body-1 mx-0 pb-2">
      <div>{{ acao.descricao }}</div>
      <div v-if="exibirCampo('periodo_planejamento')">{{ $t('label.data_inicio') }}: {{ acao.dtaInicio }}</div>
      <div v-if="exibirCampo('periodo_planejamento')">{{ $t('label.data_fim') }}: {{ acao.dtaFim }}</div>
      <div>{{ $tc('label.responsavel', 1) }}: {{ acao.responsaveis }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PopoverLista from '../../../shared-components/PopoverLista';

export default {
  components: {
    PopoverLista,
  },
  props: {
    acao: Object,
    configuracao: Object,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
    ]),
    exibirCampo(campo) {
      if (this.configuracao.passo3) {
        const { listaPadrao } = this.configuracao.passo3;
        return !!listaPadrao.filter((p) => p.fluxos.includes('LIQUIDACAO') && p.label === campo).length;
      }
      return false;
    },
    listaPopover() {
      const lista = [];
      if (this.exibirCampo('status')) {
        lista.push({ campo: 'status' });
      }
      if (this.exibirCampo('unidadenegocio')) {
        lista.push({ campo: 'unidadeNegocio', label: this.$tc('label.unidade_negocio', 1) });
      }

      if (this.configuracao.passo3) {
        const { listaDinamica } = this.configuracao.passo3;
        const camposApuracao = listaDinamica.filter((p) => p.fluxos.includes('LIQUIDACAO'));
        camposApuracao.forEach((a) => lista.push({
          campo: a.label,
          label: this.montaLabelCampoDinamico(a.label),
        }));
      }
      return lista;
    },
    formatarObjeto() {
      const acaoFormatada = { ...this.acao };
      if (this.acao && this.acao.statusApuracao) {
        acaoFormatada.status = this.$tc(`status_entidade.${this.acao.statusApuracao.toLowerCase()}`, 1);
      }
      return acaoFormatada;
    },
    montaLabelCampoDinamico(campo) {
      const metadado = this.getAcaoMetadado.mapaCamposDinamicos;
      return metadado[campo].nomCampo;
    },
  },
  beforeMount() {
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
  },
};
</script>
