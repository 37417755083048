<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">{{ $tc('label.fornecedor', 1)}}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <card-info :campos="campos" :objeto="formatarObjeto()" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import CardInfo from '../../../shared-components/CardInfo';

export default {
  props: {
    acao: Object,
  },
  components: {
    CardInfo,
  },
  data() {
    return {
      campos: [
        { campo: 'fornecedor', label: this.$tc('label.fornecedor', 1), hint: 'cnpj' },
        { campo: 'grupoFornecedor', label: this.$tc('label.grupo_fornecedor', 1) },
        { campo: 'tipoPagamento', label: this.$tc('label.tipo_pagamento', 1) },
        { campo: 'fornecedorPagador', label: this.$tc('label.fornecedor_pagador', 1), hint: 'cnpj' },
        { campo: 'prazoVencimentoAcao', label: this.$tc('label.prazo_vencimento_dias', 1) },
      ],
    };
  },
  computed: {
  },
  methods: {
    formatarObjeto() {
      const acaoFormatada = { ...this.acao };
      if (this.acao && this.acao.tipoPagamento) {
        acaoFormatada.tipoPagamento = this.$tc(`label.${this.acao.tipoPagamento.toLowerCase()}`, 1);
      }
      return acaoFormatada;
    },
  },
};
</script>
